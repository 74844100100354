require('./box.js');
require('./circle.js');
require('./cone.js');
require('./cylinder.js');
require('./dodecahedron.js');
require('./icosahedron.js');
require('./octahedron.js');
require('./plane.js');
require('./ring.js');
require('./sphere.js');
require('./tetrahedron.js');
require('./torus.js');
require('./torusKnot.js');
require('./triangle.js');
