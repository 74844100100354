require('./primitives/a-camera');
require('./primitives/a-cursor');
require('./primitives/a-curvedimage');
require('./primitives/a-gltf-model');
require('./primitives/a-image');
require('./primitives/a-light');
require('./primitives/a-link');
require('./primitives/a-obj-model');
require('./primitives/a-sky');
require('./primitives/a-sound');
require('./primitives/a-text');
require('./primitives/a-video');
require('./primitives/a-videosphere');
require('./primitives/meshPrimitives');
